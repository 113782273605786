import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import routes from '../core/routes';

import Dashboard from '../pages/Dashboard';
import Events from '../pages/Events';
import DiscussionGroups from '../pages/DiscussionGroups';
import Messages from '../pages/Messages';
import Training from '../pages/MyTraining';
import NotificationCenter from '../pages/NotificationCenter';
import Reports from '../pages/Reports';
import AllApps from '../pages/AllApps';
import AllUpdates from '../pages/AllUpdates';
import CallbackRouter from './CallbackRouter';
import AllNotifications from '../pages/AllNotifications';
import NotFoundPageContainer from '../pages/NotFoundPageContainer';
import Search from '../pages/Search';
import Analytics from '../pages/Analytics';
import ReportDetails from '../components/Analytics/ReportDetails';
import Help from '../pages/Help';
import Infractions from '../pages/Infractions';
import { useGetUserQuery } from '../services/apis/usersAPI';
import NotAuthorized from '../pages/NotAuthorized';
import GuidelinesNotAccepted from '../components/ElementaryComponents/GuidelinesNotAccepted';
import Subscriptions from '../pages/Subscriptions';
import CommunityGuidelinesPage from '../pages/CommunityGuidelinesPage';
import ModerationReports from '../pages/ModerationReports';

const AppRouter = () => {
  const { data } = useGetUserQuery();
  const is_moderation_authorized = data?.access_sidebar?.moderation;
  const is_notification_authorized = data?.access_sidebar?.notification;
  const is_analytics_authorized = data?.access_sidebar?.report;
  const latest_guidelines_status = data?.data?.latest_guidelines_status;
  const guidelines_accepted = latest_guidelines_status === 'accepted';

  return (
    <Routes>
      <Route
        index
        element={<Navigate to={routes.dashboard.url} replace />}
      />
      <Route
        path={routes.dashboard.url}
        element={<Dashboard />}
      />
      <Route
        path={routes.forum.url}
        element={
          guidelines_accepted
            ? <DiscussionGroups guidelines_accepted={guidelines_accepted} />
            : <GuidelinesNotAccepted />
          }
      />
      <Route
        path={`${routes.forum.url}/:group_id`}
        element={guidelines_accepted
          ? <DiscussionGroups guidelines_accepted={guidelines_accepted} />
          : <GuidelinesNotAccepted />}
      />
      <Route
        path={`${routes.forum.url}/:group_id/:post_id`}
        element={guidelines_accepted
          ? <DiscussionGroups guidelines_accepted={guidelines_accepted} />
          : <GuidelinesNotAccepted />}
      />
      <Route
        path={`${routes.forum.url}/:group_id/:post_id/:comment_id`}
        element={guidelines_accepted
          ? <DiscussionGroups guidelines_accepted={guidelines_accepted} />
          : <GuidelinesNotAccepted />}
      />
      <Route
        path={`${routes.communityGuideLines.url}`}
        element={<CommunityGuidelinesPage />}
      />
      <Route
        path={routes.training.url}
        element={<Training guidelines_accepted={guidelines_accepted} />}
      />
      <Route
        path={`${routes.training.url}/:course_id`}
        element={<Training guidelines_accepted={guidelines_accepted} />}
      />
      <Route path={routes.moderationReport.url} element={<ModerationReports />} />
      <Route
        path={routes.reports.url}
        element={is_moderation_authorized
          ? (guidelines_accepted ? <Reports /> : <GuidelinesNotAccepted />)
          : <NotAuthorized />}
      />
      <Route
        path={`${routes.reports.url}/:report_id`}
        element={is_moderation_authorized
          ? (guidelines_accepted ? <Reports /> : <GuidelinesNotAccepted />)
          : <NotAuthorized />}
      />
      <Route
        path={routes.events.url}
        element={<Events guidelines_accepted={guidelines_accepted} />}
      />
      <Route
        path={`${routes.events.url}/:event_id`}
        element={<Events guidelines_accepted={guidelines_accepted} />}
      />
      <Route
        path={routes.messages.url}
        element={guidelines_accepted ? <Messages /> : <GuidelinesNotAccepted />}
      />
      {/* <Route
        path={routes.notificationCenter.url}
        element={guidelines_accepted ? <NotificationCenter /> : <GuidelinesNotAccepted />}
      /> */}
      <Route
        path={routes.notificationCenter.url}
        element={is_notification_authorized
          ? (guidelines_accepted ? <NotificationCenter /> : <GuidelinesNotAccepted />)
          : <NotAuthorized />}
      />
      <Route
        path={routes.search.url}
        element={guidelines_accepted ? <Search /> : <GuidelinesNotAccepted />}
      />
      <Route
        path={routes.help.url}
        element={<Help guidelines_accepted={guidelines_accepted} />}
      />
      <Route
        path={routes.allApps.url}
        element={<AllApps guidelines_accepted={guidelines_accepted} />}
      />
      <Route
        path={routes.allUpdates.url}
        element={guidelines_accepted ? <AllUpdates /> : <GuidelinesNotAccepted />}
      />
      <Route
        path={routes.analytics.url}
        element={
          is_analytics_authorized
            ? guidelines_accepted
              ? <Analytics />
              : <GuidelinesNotAccepted />
            : <NotAuthorized />
          }
      />
      <Route
        path={`${routes.analytics.url}/:report_id`}
        element={is_analytics_authorized
          ? (guidelines_accepted
            ? <ReportDetails />
            : <GuidelinesNotAccepted />
          )
          : <NotAuthorized />}
      />
      <Route
        path={`${routes.infractions.url}`}
        element={guidelines_accepted ? <Infractions /> : <GuidelinesNotAccepted />}
      />
      <Route
        path={routes.allNotifications.url}
        element={<AllNotifications />}
      />
      <Route
        path={routes.subscriptions.url}
        element={<Subscriptions />}
      />
      <Route
        path={routes.authCallback.url}
        element={<CallbackRouter />}
      />
      <Route
        path={routes.notFound.url}
        element={<NotFoundPageContainer />}
      >
        <Route
          path="unauthorized"
          element={<NotAuthorized />}
        />
      </Route>
      <Route
        path="*"
        element={<Navigate to={routes.notFound.url} replace />}
      />
    </Routes>
  );
};

export default AppRouter;
